<template>
  <a-card title="车场收入周排行榜">
    <div style="padding: 4px 0">
      <a-table
        row-key="parkingLotId"
        table-layout="fixed"
        :data-source="slotList.list"
        :columns="columns"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 500 }"
        bordered
      >
        <template #shouldPayAmount="{ text }">
          {{ money(text) }}
        </template>
        <template #paidAmount="{ text }">
          {{ money(text) }}
        </template>
        <template #oweAmount="{ text }">
          {{ money(text) }}
        </template>
        <template #refundAmount="{ text }">
          {{ money(text) }}
        </template>
      </a-table>
    </div>
    <a-pagination
      v-model:current="page"
      v-model:page-size="pageSize"
      :total="total"
      :show-total="total => `${total}条数据`"
      @change="currentChange"
    >
    </a-pagination>
  </a-card>
</template>

<script>
import useDashboard from "@/hooks/useDashboard";
import { onMounted, ref } from "vue";
import { amountweeklyRanking } from "@/api/dashboardApi";
import useMessage from "@/hooks/useMessage";
import usePagination from "@/hooks/usePagination";

export default {
  name: "AnalysisTag",
  setup() {
    const loading = ref(false);
    const { showErr } = useMessage();
    const { slotList, money } = useDashboard();
    const total = ref("0");
    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);
    const columns = [
      {
        title: "序号",
        align: "center",
        width: "80px",
        customRender: ({ index }) => {
          return index + 1;
        },
      },
      {
        title: "车场",
        align: "center",
        dataIndex: "parkinglotName",
      },
      {
        title: "应收金额(元)",
        align: "center",
        dataIndex: "shouldPayAmount",
        slots: { customRender: "shouldPayAmount" },
      },
      {
        title: "已结算金额(元)",
        align: "center",
        dataIndex: "paidAmount",
        slots: { customRender: "paidAmount" },
      },
      {
        title: "欠缴金额(元)",
        align: "center",
        dataIndex: "oweAmount",
        slots: { customRender: "oweAmount" },
      },
      {
        title: "退款金额(元)",
        align: "center",
        dataIndex: "refundAmount",
        slots: { customRender: "refundAmount" },
      },
    ];

    onMounted(async () => {
      loadData();
    });

    const loadData = async (page, pageSize) => {
      loading.value = true;
      try {
        let r = await amountweeklyRanking(page, pageSize);
        slotList.list = r.data;
        total.value = r.total;
        loading.value = false;
      } catch (e) {
        loading.value = false;
        showErr(e);
      }
    };

    return {
      columns,
      loading,
      slotList,
      total,
      page,
      pageSize,
      currentChange,
      doQuery,
      doRefresh,
      loadData,
      money,
    };
  },
};
</script>
